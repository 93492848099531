import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    viewTye = new BehaviorSubject<string>('');

    setViewType(type: string): void {
        this.viewTye.next(type);
    }

    getViewType(): Observable<string> {
        return this.viewTye.asObservable();
    }
}