import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../../service/auth.service";
import { MenuItem } from "primeng/api";
import { MsalBroadcastService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { filter } from "rxjs";
import { GMIViewsService } from "../../service/gmi-views.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    viewType: string = '';
    userDetails: any = null;
    menuItems: MenuItem[] = [];
    showNotificationPanel: boolean = false;
    notificationCount: string = '0';
    intervalId: any;
    notifications: any[] = [];

    constructor(
        private readonly authService: AuthService,
        private msalBroadcastService: MsalBroadcastService,
        private readonly gmiViewService: GMIViewsService
    ){}

    ngOnInit(): void {
        this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None)
        )
        .subscribe(() => {
            this.authService.setActiveAccount();
        });
        this.menuItems = [
            {
                label: 'Logout',
                icon: 'pi pi-sign-out',
                command: () => {
                    this.logout();
                }
            }
        ]
        this.authService.getActiveUser().subscribe(value => {
            this.userDetails = value;
            
        });
        this.intervalId = setInterval(() => {
            this.getNotifications();
        }, 10000);
    }

    logout(): void {
        this.authService.signOut();
    }

    getNotifications(): void {
        const subscription = this.gmiViewService.getNotifications().subscribe(response => {
            this.notificationCount = response?.length + '';
            this.notifications = response;
            subscription.unsubscribe();
        }, (error) => {
            console.log(error);
            this.notificationCount = '0';
            this.notifications = [];
            subscription.unsubscribe()
        })
    }

    updateNotification(notification: any): void {
        const subscription = this.gmiViewService.updateNotification(notification).subscribe(response => {
            subscription.unsubscribe();
            this.getNotifications();
        }, (error) => {
            console.log(error);
            subscription.unsubscribe()
        })
    }

    ngOnDestroy() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
    }
}