import { DropdownOptions } from "../models/common.model";

export const REINSTATEMENT_COLUMNS: any[] = [
    {
        header: 'ID',
        field: 'ID',
    },
    {
        header: 'MarketDataType',
        field: 'MarketDataType',
    },
    {
        header: 'PrevSupplierName',
        field: 'PrevSupplierName',
    },
    {
        header: 'NewSupplierName',
        field: 'NewSupplierName',
        editable: true,
        type: 'select'
    },
    {
        header: 'PrevCountryName',
        field: 'PrevCountryName',
    },
    {
        header: 'NewCountryCode',
        field: 'NewCountryCode',
    },
    {
        header: 'NewCountryName',
        field: 'NewCountryName',
        editable: true,
        type: 'select'
    },
    {
        header: 'Category',
        field: 'Category',
    },
    {
        header: 'Market',
        field: 'Market',
    },
    {
        header: 'Sector',
        field: 'Sector',
    },
    {
        header: 'SubSector',
        field: 'SubSector',
    },
    {
        header: 'Segment',
        field: 'Segment',
    },
    {
        header: 'PrevProductFormName',
        field: 'PrevProductFormName'
    },
    {
        header: 'NewProductFormCode',
        field: 'NewProductFormCode',
    },
    {
        header: 'NewProductFormName',
        field: 'NewProductFormName',
        editable: true,
        type: 'select'
    },
    {
        header: 'ProductAttrGrpID',
        field: 'ProductAttrGrpID',
    },
    {
        header: 'Gender',
        field: 'Gender',
        editable: true,
        type: 'select'
    },
    {
        header: 'LaundryVariants',
        field: 'LaundryVariants',
        editable: true,
        type: 'select'
    },
    {
        header: 'PackType',
        field: 'PackType',
        editable: true,
        type: 'select'
    },
    {
        header: 'Format',
        field: 'Format',
        editable: true,
        type: 'select'
    },
    {
        header: 'FatContent',
        field: 'FatContent',
        editable: true,
        type: 'select'
    },
    {
        header: 'BenefitClaim',
        field: 'BenefitClaim',
        editable: true,
        type: 'select'
    },
    {
        header: 'TargetUse',
        field: 'TargetUse',
        editable: true,
        type: 'select'
    },
    {
        header: 'Formation',
        field: 'Formation',
        editable: true,
        type: 'select'
    },
    {
        header: 'IfAntiPerspirant',
        field: 'IfAntiPerspirant',
        editable: true,
        type: 'select'
    },
    {
        header: 'IfConcentrate',
        field: 'IfConcentrate',
        editable: true,
        type: 'select'
    },
    {
        header: 'IfRinseOff',
        field: 'IfRinseOff',
        editable: true,
        type: 'select'
    },
    {
        header: 'IfHighSuds',
        field: 'IfHighSuds',
        editable: true,
        type: 'select'
    },
    {
        header: 'ATTR13_DESC_NotCategorized',
        field: 'ATTR13_DESC_NotCategorized'
    },
    {
        header: 'PrevBrandName',
        field: 'PrevBrandName',
    },
    {
        header: 'NewBrandCode',
        field: 'NewBrandCode',
    },
    {
        header: 'NewBrandName',
        field: 'NewBrandName',
        editable: true,
        type: 'select'
    },
    {
        header: 'PrevLocalManufacturerName',
        field: 'PrevLocalManufacturerName',
    },
    {
        header: 'NewLocalManufacturerCode',
        field: 'NewLocalManufacturerCode'
    },
    {
        header: 'NewLocalManufacturerName',
        field: 'NewLocalManufacturerName',
        editable: true,
        type: 'select'
    },
    {
        header: 'BrandPositionName',
        field: 'BrandPositionName',
    },
    {
        header: 'GlobalManufacturerName',
        field: 'GlobalManufacturerName',
    },
    {
        header: 'SourceName',
        field: 'SourceName',
    },
    {
        header: 'PeriodicityCode',
        field: 'PeriodicityCode',
    },
    {
        header: 'NewLocalPeriodicity',
        field: 'NewLocalPeriodicity',
        editable: true,
        type: 'select'
    },
    {
        header: 'LatestAvailableDate',
        field: 'LatestAvailableDate',
        fieldType: 'date'
    },
    {
        header: 'PrevReportingCurrencyCode',
        field: 'PrevReportingCurrencyCode',
    },
    {
        header: 'NewReportingCurrencyCode',
        field: 'NewReportingCurrencyCode',
        editable: true,
        type: 'select'
    },
    {
        header: 'DefaultUOM',
        field: 'DefaultUOM',
    },
    {
        header: 'NewItemUnitOfMeasure',
        field: 'NewItemUnitOfMeasure',
        editable: true,
        type: 'select'
    },
    {
        header: 'BadDataQualityFlag',
        field: 'BadDataQualityFlag',
    },
    {
        header: 'DiscontinuedDataFlag',
        field: 'DiscontinuedDataFlag',
    },
    {
        header: 'ReinstatementFlag',
        field: 'ReinstatementFlag',
        editable: true,
        type: 'text'
    },
    {
        header: 'LastModifiedDate',
        field: 'LastModifiedDate',
        fieldType: 'date'
    }
];

export const DEACTIVATION_COUMNS: any[] = [
    {
        header: 'ID',
        field: 'ID',
    },
    {
        header: 'MarketDataType',
        field: 'MarketDataType',
    },
    {
        header: 'SupplierName',
        field: 'SupplierName',
    },
    {
        header: 'Country',
        field: 'Country',
    },
    {
        header: 'Category',
        field: 'Category',
    },
    {
        header: 'Market',
        field: 'Market',
    },
    {
        header: 'Sector',
        field: 'Sector',
    },
    {
        header: 'SubSector',
        field: 'SubSector',
    },
    {
        header: 'Segment',
        field: 'Segment',
    },
    {
        header: 'ProductFormName',
        field: 'ProductFormName',
    },
    {
        header: 'ProductFormCode',
        field: 'ProductFormCode',
    },
    {
        header: 'Gender',
        field: 'Gender',
    },
    {
        header: 'LaundryVariants',
        field: 'LaundryVariants',
    },
    {
        header: 'PackType',
        field: 'PackType',
    },
    {
        header: 'Format',
        field: 'Format',
    },
    {
        header: 'FatContent',
        field: 'FatContent',
    },
    {
        header: 'BenefitClaim',
        field: 'BenefitClaim',
    },
    {
        header: 'TargetUse',
        field: 'TargetUse',
    },
    {
        header: 'Formation',
        field: 'Formation',
    },
    {
        header: 'IfAntiperspirant',
        field: 'IfAntiperspirant',
    },
    {
        header: 'IfConcentrate',
        field: 'IfConcentrate',
    },
    {
        header: 'IfRinseOff',
        field: 'IfRinseOff',
    },
    {
        header: 'IfHighSuds',
        field: 'IfHighSuds',
    },
    {
        header: 'ATTR13_DESC_NotCategorized',
        field: 'ATTR13_DESC_NotCategorized',
    },
    {
        header: 'BrandCode',
        field: 'BrandCode',
    },
    {
        header: 'LocalBrandName',
        field: 'LocalBrandName',
    },
    {
        header: 'LocalManufacturerCode',
        field: 'LocalManufacturerCode',
    },
    {
        header: 'LocalManufacturerName',
        field: 'LocalManufacturerName',
    },
    {
        header: 'BrandPositionName',
        field: 'BrandPositionName',
    },
    {
        header: 'GlobalManufacturerName',
        field: 'GlobalManufacturerName',
    },
    {
        header: 'SourceName',
        field: 'SourceName',
    },
    {
        header: 'LocalPeriodicity',
        field: 'LocalPeriodicity',
    },
    {
        header: 'LatestAvailableDate',
        field: 'LatestAvailableDate',
        fieldType: 'date'
    },
    {
        header: 'PreviousItemUnitOfMeasure',
        field: 'PreviousItemUnitOfMeasure',
    },
    {
        header: 'ItemUnitOfMeasure',
        field: 'ItemUnitOfMeasure'
    },
    {
        header: 'PreviousBadDataQualityFlag',
        field: 'PreviousBadDataQualityFlag',
    },
    {
        header: 'BadDataQualityFlag',
        field: 'BadDataQualityFlag',
        editable: true,
        type: 'select'
    },
    {
        header: 'PreviousDiscontinuedDataFlag',
        field: 'PreviousDiscontinuedDataFlag'
    },
    {
        header: 'DiscontinuedDataFlag',
        field: 'DiscontinuedDataFlag',
        editable: true,
        type: 'select'
    },
    {
        header: 'LastModifiedDate',
        field: 'LastModifiedDate',
        fieldType: 'date'
    }
];

export const HISTORY_CHANGE_COUMNS: any[] = [
    {
        header: 'ID',
        field: 'ID',
    },
    {
        header: 'MarketDataType',
        field: 'MarketDataType',
    },
    {
        header: 'SupplierName',
        field: 'SupplierName',
    },
    {
        header: 'Country',
        field: 'Country',
    },
    {
        header: 'Category',
        field: 'Category',
    },
    {
        header: 'Market',
        field: 'Market',
    },
    {
        header: 'Sector',
        field: 'Sector',
    },
    {
        header: 'SubSector',
        field: 'SubSector',
    },
    {
        header: 'Segment',
        field: 'Segment',
    },
    {
        header: 'ProductFormName',
        field: 'ProductFormName',
    },
    {
        header: 'ProductFormCode',
        field: 'ProductFormCode',
    },
    {
        header: 'Gender',
        field: 'Gender',
    },
    {
        header: 'LaundryVariants',
        field: 'LaundryVariants',
    },
    {
        header: 'PackType',
        field: 'PackType',
    },
    {
        header: 'Format',
        field: 'Format',
    },
    {
        header: 'FatContent',
        field: 'FatContent',
    },
    {
        header: 'BenefitClaim',
        field: 'BenefitClaim',
    },
    {
        header: 'TargetUse',
        field: 'TargetUse',
    },
    {
        header: 'Formation',
        field: 'Formation',
    },
    {
        header: 'IfAntiperspirant',
        field: 'IfAntiperspirant',
    },
    {
        header: 'IfConcentrate',
        field: 'IfConcentrate',
    },
    {
        header: 'IfRinseOff',
        field: 'IfRinseOff',
    },
    {
        header: 'IfHighSuds',
        field: 'IfHighSuds',
    },
    {
        header: 'ATTR13_DESC_NotCategorized',
        field: 'ATTR13_DESC_NotCategorized',
    },
    {
        header: 'BrandCode',
        field: 'BrandCode',
    },
    {
        header: 'LocalBrandName',
        field: 'LocalBrandName',
    },
    {
        header: 'LocalManufacturerCode',
        field: 'LocalManufacturerCode',
    },
    {
        header: 'LocalManufacturerName',
        field: 'LocalManufacturerName',
    },
    {
        header: 'BrandPositionName',
        field: 'BrandPositionName',
    },
    {
        header: 'GlobalManufacturerName',
        field: 'GlobalManufacturerName',
    },
    {
        header: 'SourceName',
        field: 'SourceName',
    },
    {
        header: 'LocalPeriodicity',
        field: 'LocalPeriodicity',
    },
    {
        header: 'LatestAvailableDate',
        field: 'LatestAvailableDate',
        fieldType: 'date'
    },
    {
        header: 'BadDataQualityFlag',
        field: 'BadDataQualityFlag',
    },
    {
        header: 'DiscontinuedDataFlag',
        field: 'DiscontinuedDataFlag',
    },
    {
        header: 'ReportingCurrency',
        field: 'ReportingCurrency',
    },
    {
        header: 'DefaultUOM',
        field: 'DefaultUOM',
    },
    {
        header: 'PreviousItemUnitOfMeasure',
        field: 'PreviousItemUnitOfMeasure',
    },
    {
        header: 'ItemUnitOfMeasure',
        field: 'ItemUnitOfMeasure',
        editable: true,
        type: 'select'
    },
    {
        header: 'MeasureType',
        field: 'MeasureType',
    },
    {
        header: 'ChannelDescription',
        field: 'ChannelDescription',
    },
    {
        header: 'ReportingStartDate',
        field: 'ReportingStartDate',
        fieldType: 'date'
    },
    {
        header: 'ReportingEndDate',
        field: 'ReportingEndDate',
        fieldType: 'date'
    },
    {
        header: 'SalesValue',
        field: 'SalesValue',
    },
    {
        header: 'PreviousModifiedSalesValue',
        field: 'PreviousModifiedSalesValue',
    },
    {
        header: 'NewSalesValue',
        field: 'NewSalesValue',
        editable: true,
        type: 'text'
    },
    {
        header: 'SalesVolume',
        field: 'SalesVolume',
    },
    {
        header: 'PreviousModifiedSalesVolume',
        field: 'PreviousModifiedSalesVolume',
    },
    {
        header: 'NewSalesVolume',
        field: 'NewSalesVolume',
        editable: true,
        type: 'text'
    },
    {
        header: 'LastModifiedDate',
        field: 'LastModifiedDate',
        fieldType: 'date'
    }
];

export const VIEW_COLUMN_MAPPING: {
    [key: string]: any[]
} = {
    reinstatements: REINSTATEMENT_COLUMNS,
    deactivations: DEACTIVATION_COUMNS,
    historyChanges: HISTORY_CHANGE_COUMNS
};

export const PRODUCT_ATTRIBUTE_FIELD_MAPPING_TO_CLEAR: {
    [key: string]: string[]
} = {
    division: ['categoryGroup', 'subDivision', 'category', 'market', 'sector', 'subSector', 'segment', 'productFormCode'],
    categoryGroup: ['subDivision', 'category', 'market', 'sector', 'subSector', 'segment', 'productFormCode'],
    subDivision: ['category', 'market', 'sector', 'subSector', 'segment', 'productFormCode'],
    category: ['market', 'sector', 'subSector', 'segment', 'productFormCode'],
    market: ['sector', 'subSector', 'segment', 'productFormCode'],
    sector: ['subSector', 'segment', 'productFormCode'],
    subSector: ['segment', 'productFormCode'],
    segment: ['productFormCode']
}

export const GEOGRAPHY_FIELD_MAPPING_TO_CLEAR: {
    [key: string]: string[]
} = {
    region: ['cluster', 'countryCluster', 'countryGroup'],
    cluster: ['countryCluster', 'countryGroup'],
    countryCluster: ['countryGroup'],
}

export const UOM_OPTIONS: DropdownOptions[] = [
    {
        name: 'ZKGLTR',
        code: 'ZKGLTR'
    },
    {
        name: 'EA',
        code: 'EA'
    },
    {
        name: 'ZWS',
        code: 'ZWS'
    }
];

export const LOCAL_PERIODICITY_OPTIONS: DropdownOptions[] = [
    {
        name: 'Bi-Monthly',
        code: 'B'
    },
    {
        name: '4-Weekly',
        code: 'V'
    },
    {
        name: 'Weekly',
        code: 'W'
    },
    {
        name: 'Monthly',
        code: 'M'
    },
    {
        name: 'Quaterly',
        code: 'Q'
    }
]

export const MEASURE_TYPE_OPTIONS: DropdownOptions[] = [
    {
        name: 'STD',
        code: 'STD'
    }
];

export const YES_NO_OPTIONS: DropdownOptions[] = [
    {
        name: 'Yes',
        code: 'Yes'
    },
    {
        name: 'No',
        code: 'No'
    }
];

export const EDITABLE_COLUMN_CODE_COLUMN_MAPPING: any = {
    reinstatements: {
        NewSupplierName: 'NewSupplierID',
        NewCountryName: 'NewCountryCode',
        NewProductFormName: 'NewProductFormCode',
        NewBrandName: 'NewBrandCode',
        NewLocalManufacturerName: 'NewLocalManufacturerCode',
        NewLocalPeriodicity: 'PeriodicityCode'
    }
};

export const EDITABLE_COLUMN_MAPPING = {
    NewSupplierName: 'suppliers',
    NewCountryName: 'country',
    NewProductFormName: 'productForm',
    Gender: 'gender',
    LaundryVariants: 'laundry',
    PackType: 'packType',
    Format: 'format',
    FatContent: 'fatContent',
    BenefitClaim: 'benefitClaim',
    TargetUse: 'targetUse',
    Formation: 'formation',
    IfAntiperspirant: 'ifAntiperspirant',
    IfConcentrate: 'ifConcentrate',
    IfRinseOff: 'ifRinseOff',
    IfHighSuds: 'ifHighSuds',
    NewBrandName: 'brand',
    NewLocalManufacturerName: 'localManufacturer',
    NewLocalPeriodicity: 'localPeriodicity',
    NewReportingCurrencyCode: 'currency',
    NewItemUnitOfMeasure: 'uom'
}