// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiPath: 'https://ait-dev.unilever.com',
  azure: {
    clientId: '5514cf91-185e-424c-ac80-66ea621fde72',
    tenantID: 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
  }
};
