import { Component, OnInit, ViewChild } from "@angular/core";
import { GMIViewsService } from "../../shared/service/gmi-views.service";
import { EDITABLE_COLUMN_CODE_COLUMN_MAPPING, LOCAL_PERIODICITY_OPTIONS, UOM_OPTIONS, VIEW_COLUMN_MAPPING, YES_NO_OPTIONS } from "../../shared/constant/sample-data.constant";
import { DropdownOptions } from "../../shared/models/common.model";
import { ActivatedRoute, Router } from "@angular/router";
import { DATAKEY_MAPPING, PAGE_SIZES, VIEW_OPTIONS } from "../../shared/constant/views.constant";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from "../../shared/service/common.service";
import { GmiDropdownService } from "../../shared/service/gmi-dropdown.service";
import { Table } from 'primeng/table'
import { ToastrService } from "ngx-toastr";


@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

    columns: any[] = [];
    rows: any[] = [];
    viewOptions: DropdownOptions[] = VIEW_OPTIONS;
    selectedView: DropdownOptions = VIEW_OPTIONS[0];
    loaderColor: string = '#3861b2';
    isSubmitDisabled: boolean = true;
    totalRecords: number = 0;
    pageSizes: number[] = PAGE_SIZES;
    datakey: string = '';
    copyRows: any[] = [];
    filterInputs: any = {
        limit: 50,
        offset: 0
    };
    editableRowOptions: any = {
        reinstatements: {
            NewSupplierName: [],
            NewCountryName: [],
            NewProductFormName: [],
            Gender: [],
            LaundryVariants: [],
            PackType: [],
            Format: [],
            FatContent: [],
            BenefitClaim: [],
            TargetUse: [],
            Formation: [],
            IfAntiperspirant: [],
            IfConcentrate: [],
            IfRinseOff: [],
            IfHighSuds: [],
            NewBrandName: [],
            NewLocalManufacturerName: [],
            NewLocalPeriodicity: LOCAL_PERIODICITY_OPTIONS,
            NewReportingCurrencyCode: [],
            NewItemUnitOfMeasure: UOM_OPTIONS
        },
        deactivations: {
            ItemUnitOfMeasure: UOM_OPTIONS,
            BadDataQualityFlag: YES_NO_OPTIONS,
            DiscontinuedDataFlag: YES_NO_OPTIONS
        },
        historyChanges: {
            ItemUnitOfMeasure: UOM_OPTIONS
        }
        
    };
    selectedRow: any = null;

    @ViewChild('pTable', { static: false }) table: Table;


    constructor(
        private readonly viewService: GMIViewsService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ngxLoaderService: NgxUiLoaderService,
        private readonly commonService: CommonService,
        private readonly gmiDimService: GmiDropdownService,
        private readonly toastrService: ToastrService
    ){}

    ngOnInit(): void {
        const type = this.route.snapshot.paramMap.get('type');
        this.selectedView = this.viewOptions.find(x => x.code === type ) || VIEW_OPTIONS[0];
        this.commonService.setViewType(type);
        this.commonService.getViewType().subscribe(value => {
            delete this.filterInputs.query;
            this.isSubmitDisabled = true;
            this.datakey = DATAKEY_MAPPING[value];
            this.getViewData(value);
            this.getCoulmnUpdatedData(value);
        });
    }

    onPageChange(event: any) {
        this.filterInputs.limit = event.rows;
        this.filterInputs.offset = event.first;
        this.getViewData(this.selectedView.code);
    }

    getViewData(type: string): void {
        this.ngxLoaderService.start();
        const subscription = this.viewService.getViewData(type, this.filterInputs).subscribe((response) => {
            subscription.unsubscribe();
            this.rows = response?.data;
            this.totalRecords = response?.totalRows;
            this.rows = this.getFormattedRows(this.rows);
            this.copyRows = JSON.parse(JSON.stringify(this.rows));
            
            this.ngxLoaderService.stop();
        },
        (error) => {
            subscription.unsubscribe();
            this.rows = [];
            this.copyRows = [];
            this.totalRecords = 0;
            this.ngxLoaderService.stop();
            this.toastrService.error('Failed to get view data.', `Error`);
            console.log('error', error);
        });
    }

    getCoulmnUpdatedData(viewType: string): void {
        const actionColumn: any[] = [
            {
                header: 'Action',
                field: 'action',
            }
        ];
        const columnValues: any[] = VIEW_COLUMN_MAPPING[viewType];
        this.columns = actionColumn.concat(columnValues);
    }

    routeToView(option: DropdownOptions): void {
        if (option.code === this.selectedView.code) {
            return;
        }
        this.selectedView = this.viewOptions.find(x => x.code === option.code) || VIEW_OPTIONS[0];
        this.router.navigate([`view/${option.code}`]);
        this.commonService.setViewType(option.code);
    }

    onEdit(row: any): void {
        this.table.cancelRowEdit(this.selectedRow);
        this.selectedRow = row;
        this.isSubmitDisabled = false;
        if (this.selectedView.code !== VIEW_OPTIONS[0].code || this.editableRowOptions[this.selectedView.code]?.NewSupplierName?.length) {
            return;
        }
        const subscription = this.viewService.getEditableRowDropdownData().subscribe(response => {
            this.updateRowDropdownOptions(response);
            subscription.unsubscribe();
        }, (error) => {
            console.log(error);
            subscription.unsubscribe();
        })
    }

    onCancelEdit(row: any): void {
        this.selectedRow = null;
        this.isSubmitDisabled = true;
        this.rows = JSON.parse(JSON.stringify(this.copyRows));
    }

    filterRows(filterInput: any): void {
        this.filterInputs['query'] = JSON.parse(JSON.stringify(filterInput));
        this.getViewData(this.selectedView.code);
    }

    getFormattedRows(rows: any[]): any[] {
                if (this.selectedView.code === VIEW_OPTIONS[0].code) {
            rows = rows?.map(x => {
                return {
                    ...x,
                    NewSupplierName: { name: x.NewSupplierName, code: x.NewSupplierID },
                    NewCountryName: { name: x.NewCountryName, code: x.NewCountryCode },
                    NewProductFormName: { name: x.NewProductFormName, code: x.NewProductFormCode},
                    NewBrandName: { name: x.NewBrandName, code: x.NewBrandCode },
                    NewLocalManufacturerName: { name: x.NewLocalManufacturerName, code: x.NewLocalManufacturerCode},
                    NewReportingCurrencyCode: {name: x.NewReportingCurrencyCode, code: x.NewReportingCurrencyCode},
                    NewItemUnitOfMeasure: { name: x.NewItemUnitOfMeasure, code: x.NewItemUnitOfMeasure},
                    NewLocalPeriodicity: { name: x.NewLocalPeriodicity, code: x.PeriodicityCode },
                    Gender: { name: x.Gender, code: x.Gender },
                    LaundryVariants: { name: x.LaundryVariants, code: x.LaundryVariants},
                    PackType: { name: x.PackType, code: x.PackType},
                    Format: { name: x.Format, code: x.Format},
                    FatContent: { name: x.FatContent, code: x.FatContent},
                    BenefitClaim: { name: x.BenefitClaim, code: x.BenefitClaim},
                    TargetUse: { name: x.TargetUse, code: x.TargetUse},
                    Formation: { name: x.Formation, code: x.Formation},
                    IfAntiPerspirant: { name: x.IfAntiPerspirant, code: x.IfAntiPerspirant},
                    IfConcentrate: { name: x.IfConcentrate, code: x.IfConcentrate},
                    IfRinseOff: { name: x.IfRinseOff, code: x.IfRinseOff },
                    IfHighSuds: { name: x.IfHighSuds, code: x.IfHighSuds}
                }
            });
        } else if (this.selectedView.code === VIEW_OPTIONS[1].code) {
            rows = rows?.map(x => {
                return {
                    ...x,
                    BadDataQualityFlag: { name: x.BadDataQualityFlag, code: x.BadDataQualityFlag},
                    DiscontinuedDataFlag: { name: x.DiscontinuedDataFlag, code: x.DiscontinuedDataFlag}
                }
            })
        } else if (this.selectedView.code === VIEW_OPTIONS[2].code) {
            rows = rows?.map(x => {
                return {
                    ...x,
                    ItemUnitOfMeasure: { name: x.ItemUnitOfMeasure, code: x.ItemUnitOfMeasure}
                }
            })
        }
        return rows || [];
    }

    updateRowDropdownOptions(response: any): void {
        this.editableRowOptions[this.selectedView.code].NewSupplierName = response[0];
        this.editableRowOptions[this.selectedView.code].NewCountryName = response[1];
        this.editableRowOptions[this.selectedView.code].NewProductFormName = response[2];
        this.editableRowOptions[this.selectedView.code].NewBrandName = response[3];
        this.editableRowOptions[this.selectedView.code].NewLocalManufacturerName = response[4];
        this.editableRowOptions[this.selectedView.code].NewReportingCurrencyCode = response[5];
        this.updateProductAttributeFieldOptions(response[6]);
    }

    updateProductAttributeFieldOptions(data: any): void {
        Object.keys(data)?.forEach(x => {
            this.editableRowOptions[this.selectedView.code][x] = data[x]?.filter(item => item.code);
        });
    }

    onSelect(event: any, field: string, row: any): void {
        if (this.selectedView.code === VIEW_OPTIONS[0].code) {
            row[EDITABLE_COLUMN_CODE_COLUMN_MAPPING[this.selectedView.code][field]] = event.value?.code;
        }
    }

    rowUpdate(row: any): void {
        this.ngxLoaderService.start();
        const payload = this.formatFormToPayload(this.selectedRow);
        const subscription = this.viewService.updateRow(this.selectedView.code, payload).subscribe(response => {
            subscription.unsubscribe();
            this.ngxLoaderService.stop();
            this.toastrService.success('Record Updated Successfully.', 'Success');
            this.getViewData(this.selectedView.code);
            this.table.cancelRowEdit(this.selectedRow);
            this.isSubmitDisabled = true; 
        }, (error) => {
            this.rows = this.copyRows;
            subscription.unsubscribe();
            this.ngxLoaderService.stop();
            this.toastrService.error('Failed to Update Record.', `Error`);
        });
               
    }

    formatFormToPayload(row: any) {
        const rowPayload = JSON.parse(JSON.stringify(row));
        if (rowPayload) {
            Object.keys(this.editableRowOptions[this.selectedView.code]).forEach(x => {
                rowPayload[x] = rowPayload[x]?.name
            });
        }
        return rowPayload;
    }

    updateRecords(data: any): void {
        this.ngxLoaderService.start();
        let payload = JSON.parse(JSON.stringify(data));
        payload['query'] = this.filterInputs?.query;
        const subscription = this.viewService.bulkUpdate(this.selectedView?.code, payload).subscribe(response => {
            subscription.unsubscribe();
            this.ngxLoaderService.stop();
            this.getViewData(this.selectedView?.code);
            this.toastrService.success(`${this.totalRecords} Records has been updated successfully.`, 'Success');
        }, (error) => {
            subscription.unsubscribe();
            this.ngxLoaderService.stop();
            this.toastrService.error('Failed to Update Records.', `Error`);
        });
    }
}