<nav class="navbar sticky-top navbar-light heading-background p-2 justify-content-between">
    <a class="navbar-brand text-white font-weight-bold" href="/view">GMI-AIT</a>
    <div class="form-inline text-white">
        <i class="pi pi-bell cursor-pointer" (click)="showNotificationPanel = !showNotificationPanel" *ngIf="notificationCount === '0'" style="font-size: 1rem; margin: 0px 20px;"></i>
        <i class="pi pi-bell cursor-pointer" (click)="showNotificationPanel = !showNotificationPanel" *ngIf="notificationCount !== '0'" pBadge [value]="notificationCount" style="font-size: 1rem; margin: 0px 20px;"></i>
        <span>{{userDetails?.name}}</span> 
        <p-menu #menu [model]="menuItems" [popup]="true"></p-menu>
        <i class="pi pi-user cursor-pointer" (click)="menu.toggle($event)" style="font-size: 1rem; margin: 0px 12px;"></i>
    </div>
</nav>
<div class="notification-panel" *ngIf="showNotificationPanel">
    <div class="form-inline" style="height: 3vh;">
        <i class="pi pi-times cursor-pointer" (click)="showNotificationPanel = false" style="color: white; font-size: 1rem; margin: 0px 12px; float: right;"></i>
    </div>
    <ng-container *ngFor="let notification of notifications">
        <div *ngIf="notification?.Status === 'Running'" class="alert alert-primary alert-dismissible fade show" style="padding-right: 10px; padding-bottom: 2.3rem;" role="alert">
            <div>
                <strong>Running</strong>
            </div>
            <div>
                History Change bulk update is in progress.
            </div>
            <div>
                <button type="button" class="btn btn-primary btn-sm" style="float: right;" (click)="updateNotification(notification)">Mark as Read</button>
            </div>
        </div>
        <div *ngIf="notification?.Status === 'Success'" class="alert alert-success alert-dismissible fade show" style="padding-right: 10px; padding-bottom: 2.3rem;" role="alert">
            <div>
                <strong>Success</strong>
            </div>
            <div>
                History Change bulk update is completed.
            </div>
            <div>
                <button type="button" class="btn btn-primary btn-sm" style="float: right;" (click)="updateNotification(notification)">Mark as Read</button>
            </div>
        </div>
        <div *ngIf="notification?.Status === 'Failed'" class="alert alert-danger alert-dismissible fade show" style="padding-right: 10px; padding-bottom: 2.3rem;" role="alert">
            <div>
                <strong>Failed</strong>
            </div>
            <div>
                History Change bulk update got failed.
            </div>
            <div>
                <button type="button" class="btn btn-primary btn-sm" style="float: right;" (click)="updateNotification(notification)">Mark as Read</button>
            </div>
        </div>
    </ng-container>
</div>