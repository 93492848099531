import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DropdownOptions } from "../../models/common.model";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { GmiDropdownService } from "../../service/gmi-dropdown.service";
import { GEOGRAPHY_FIELD_MAPPING_TO_CLEAR, MEASURE_TYPE_OPTIONS, PRODUCT_ATTRIBUTE_FIELD_MAPPING_TO_CLEAR, UOM_OPTIONS } from "../../constant/sample-data.constant";
import { CommonService } from "../../service/common.service";

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html'
})
export class FilterComponent implements OnInit {

    @Input() viewType: string = '';
    @Output() filterClick: EventEmitter<any> = new EventEmitter<any>();

    selectDropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'name',
      allowSearchFilter: false
    }

    selectedSupplier: DropdownOptions = null;
    geopgraphyInputs: any = {
        world: { name: 'World', code: 'MHGEO0001'},
        region: null,
        cluster: null,
        countryCluster: null,
        countryGroup: null,
        country: []
    };
    productAttributeInuputs: any = {
        totalGlobalCategory: {code: 'CZ2004', name: 'Total Product Category'},
        division: null,
        categoryGroup: null,
        subDivision: null,
        category: null,
        market: null,
        sector: null,
        subSector: null,
        segement: null,
        productFormCode: null
    }
    selectedBrand: DropdownOptions[] = [];
    selectedLocalManufaturer: DropdownOptions[] = [];
    discontinuedFlag: 'Yes' | 'No' | '' = '';
    badQuality: 'Yes' | 'No' | '' = '';
    productFormCodes: DropdownOptions[] = [];
    UOM: DropdownOptions[] = UOM_OPTIONS;
    measureTypeOptions: DropdownOptions[] = MEASURE_TYPE_OPTIONS;
    startDate: any;
    endDate: any;
    selectedUOM: DropdownOptions[] = [];
    selectedChannel: DropdownOptions[] = [];
    selectedMeasureType: DropdownOptions[] = [];
    selectedSourceName: DropdownOptions = null;
    exculdeResetFields: string[] = ['world', 'totalGlobalCategory'];


    constructor(
        private readonly dropdownService: GmiDropdownService,
        private readonly commonService: CommonService
    ){}

    ngOnInit(): void {
        this.getCountryCodes();
        this.getproductFormCodes();
        this.commonService.getViewType().subscribe(value => {
            this.resetForm();
        });
    }

    getCountryCodes(fieldName?: string, type?: 'country' | 'product'): void {
        if (type) {
            this.clearLowerField(fieldName, type);
        }

        const subscription = this.dropdownService.getCountryMetaData(this.geopgraphyInputs).subscribe(response => {
            if (response?.length) {
                this.geopgraphyInputs.country = response.map(x => {
                    return {
                        selected: true,
                        name: x.name,
                        code: x.code
                    }
                }).filter(x => x.code);
            } else {
                this.geopgraphyInputs.country = [];
            }
            subscription.unsubscribe();
        }, (error) => {
            this.geopgraphyInputs.country = [];
            subscription.unsubscribe();
        });
    }

    getproductFormCodes(fieldName?: string, type?: 'country' | 'product'): void {
        if (type) {
            this.clearLowerField(fieldName, type);
        }

        const subscription = this.dropdownService.getProductFormMetaData(this.productAttributeInuputs).subscribe(response => {
            if (response?.length) {
                this.productFormCodes = response.map(x => {
                    return {
                        name: x.name,
                        code: x.code
                    }
                }).filter(x => x.code);
            } else {
                this.productFormCodes = [];
            }
            subscription.unsubscribe();
        }, (error) => {
            this.productFormCodes = [];
            subscription.unsubscribe();
        });
    }

    clearLowerField(fieldName: string, type: 'country' | 'product'): void {
        if (type === 'country') {
            Object.keys(this.geopgraphyInputs).forEach(key =>{
                if (GEOGRAPHY_FIELD_MAPPING_TO_CLEAR[fieldName]?.includes(key)) {
                    this.geopgraphyInputs[key] = null;
                }
            });
        } else if (type === 'product') {
            Object.keys(this.productAttributeInuputs).forEach(key =>{
                if (PRODUCT_ATTRIBUTE_FIELD_MAPPING_TO_CLEAR[fieldName]?.includes(key)) {
                    this.productAttributeInuputs[key] = null;
                }
            });
        }
    }

    filter(): void {
        const filterInputs = {
            type: this.viewType,
            supplier: this.selectedSupplier?.code,
            geography: {
                countryCodes: this.geopgraphyInputs.country?.filter(x => x.selected).map(x=> x.code) 
            },
            productCategory: {
                productFormCode: this.productAttributeInuputs.productFormCode?.code
            },
            SourceName: this.selectedSourceName?.name,
            unitOfMeasure: this.selectedUOM.map(x => x.code),
            channel: this.selectedChannel.map(x => x.code),
            measureType: this.selectedMeasureType.map(x => x.code),
            badQuality: this.badQuality,
            discontinuedFlag: this.discontinuedFlag,
            brand: this.selectedBrand.map(x => x.code),
            localManufacturer: this.selectedLocalManufaturer.map(x => x.code),
            startDate: this.startDate?.toLocaleDateString('sv-SE'),
            endDate: this.endDate?.toLocaleDateString('sv-SE')
        };
        this.filterClick.emit(filterInputs);
    }

    resetForm(): void {
        Object.keys(this.productAttributeInuputs).forEach(key => {
            if (!this.exculdeResetFields.includes(key)) {
                this.productAttributeInuputs[key] = null;
            }
        });
        Object.keys(this.geopgraphyInputs).forEach(key => {
            if (!this.exculdeResetFields.includes(key)) {
                this.geopgraphyInputs[key] = null;
            }
        });
        this.selectedBrand = [];
        this.selectedChannel = [];
        this.selectedLocalManufaturer = [];
        this.selectedMeasureType = [];
        this.selectedSourceName = null;
        this.selectedSupplier = null;
        this.selectedUOM = [];
        this.startDate = null;
        this.endDate = null;
        this.badQuality = '';
        this.discontinuedFlag = '';
    }
}