import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ViewComponent } from './pages/view/view.component';
import { HomeComponent } from './pages/home/home.component';
import { MsalGuard } from '@azure/msal-angular';

export const AppRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'view',
        component: HomeComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'view/:type',
        component: ViewComponent,
        canActivate: [MsalGuard]
    }
];
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
