import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, concatMap, of, toArray } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class GMIViewsService {

    url: string = '';
    constructor(
        private readonly httpClient: HttpClient
    ){
        this.url = `${environment.apiPath}/api/ait/`;
    }

    getViewData(type: string, payload: any): Observable<any> {
        return this.httpClient.post<any>(`${this.url}${type}`, payload);
    }

    getEditableRowDropdownData(): Observable<any> {
        const apis: Observable<any>[] = [
            this.httpClient.get<any>(`${environment.apiPath}/api/dim/suppliers`),
            this.httpClient.post<any>(`${environment.apiPath}/api/dim/country`, {}),
            this.httpClient.post<any>(`${environment.apiPath}/api/dim/product-form`, {}),
            this.httpClient.get<any>(`${environment.apiPath}/api/dim/brand`),
            this.httpClient.get<any>(`${environment.apiPath}/api/dim/local-manufacturer`),
            this.httpClient.get<any>(`${environment.apiPath}/api/dim/currency`),
            this.httpClient.get<any>(`${environment.apiPath}/api/dim/product-attribute`)
        ];

        return of(...apis).pipe(
            concatMap(request => request.pipe(
              catchError(error => {
                return of(null);
              })
            )),
            toArray() // Collect all responses into an array
          );
      
    }

    updateRow(type: string, payload: any): Observable<any> {
        return this.httpClient.put<any>(`${this.url}${type}/update`, payload);
    }

    bulkUpdate(type: string, payload: any): Observable<any> {
        return this.httpClient.put<any>(`${this.url}${type}/bulk-update`, payload);
    }

    downloadFile(payload: any): Observable<any> {
        return this.httpClient.post<any>(`${this.url}historyChanges/download`, payload);
    }

    uploadFile(file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file as File);
        return this.httpClient.post<any>(`${this.url}historyChanges/upload`, formData);
    }

    getNotifications():Observable<any> {
        return this.httpClient.get<any>(`${environment.apiPath}/api/notifications`);
    }

    updateNotification(payload: any):Observable<any> {
        return this.httpClient.put<any>(`${environment.apiPath}/api/notifications`, payload);
    }
}