import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { DropdownOptions } from "../../models/common.model";
import { GmiDropdownService } from "../../service/gmi-dropdown.service";

@Component({
    selector: 'app-dim-dropdown',
    templateUrl: './auto-dropdown.component.html'
})
export class AutoDropdownComponent implements OnInit {

    selectDropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'value',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'Un-Select All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
    }

    @Input() placeholder: string = 'All';
    @Input() path: string = '';
    @Input() selectedValues: DropdownOptions | DropdownOptions[] = null;
    @Input() singleSelection: boolean = true;
    @Input() disabled: boolean = false;
    @Input() showClear: boolean = false;
    @Input() set query(queryVal) {
        if (queryVal?.code) {
            setTimeout(() =>{
                this.getDropdownData(this.path, queryVal.code);
            }, 100)
        }
    }

    @Input() values: DropdownOptions[] = [];
    multiSelectValues: DropdownOptions[] = [];
    
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectAll: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private readonly dropdownService: GmiDropdownService
    ) {}

    ngOnInit(): void {
        this.selectDropdownSettings.singleSelection = this.singleSelection;
        this.getDropdownData(this.path);
    }

    getDropdownData(path: string, query?: string): void {
        if (!path || this.disabled) {
            return;
        }
        const subscription = this.dropdownService.getDropdownValues(path, query).subscribe(response => {
            if (response?.length) {
                this.values = response.map(x => {
                    return  {
                        ...x,
                        name: x.name,
                        code: x.code
                    } 
                }).filter(x => x.code);
            }
            subscription.unsubscribe();
        },
        (error) => {
            subscription.unsubscribe();
            console.log(error);
        })
    }

    onChange(event: any): void {
        const selectedItem: DropdownOptions[] = event.value || null;
        this.onSelect.emit(selectedItem);
    }

    onSelectAllChange(event: any): void {
        this.selectAll.emit(event.value || null);
    }

}