<!-- <ng-multiselect-dropdown
[placeholder]="placeholder"
[settings]="selectDropdownSettings"
[data]="values"
[(ngModel)]="selectedValues"
(onSelect)="onValueSelect($event)"
(onSelectAll)="onSelectAll($event)"
(onDeSelect)="onDeSelect($event)"
>
</ng-multiselect-dropdown> -->
<ng-container *ngIf="singleSelection">
    <p-dropdown 
        id="{{'dropdown-' + path}}"
        [options]="values"
        [(ngModel)]="selectedValues"
        optionLabel="name"
        [virtualScroll]="true"
        [filter]="true"
        [virtualScrollItemSize]="30"
        (onSelectAllChange)="onSelectAllChange($event)"
        (onChange)="onChange($event)"
        [showClear]="selectedValues ? true : false"
        [disabled]="disabled"
        placeholder="All">
    </p-dropdown>
</ng-container>
<ng-container *ngIf="!singleSelection">
    <p-multiSelect 
        id="{{'multi-dropdown-' + path}}"
        [options]="values"
        [(ngModel)]="selectedValues"
        [virtualScroll]="true"
        [filter]="true"
        [virtualScrollItemSize]="30"
        (onSelectAllChange)="onSelectAllChange($event)"
        (onChange)="onChange($event)"
        optionLabel="name"
        [disabled]="disabled"
        placeholder="All">
    </p-multiSelect>
</ng-container>