import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DropdownOptions } from "../../models/common.model";

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html'
})
export class DropdownComponent implements OnInit {

    @Input() options: DropdownOptions[] = [];
    @Input() selectedOption: DropdownOptions = {};
    @Input() widthClass: string = 'w-50';

    @Output() select = new EventEmitter<DropdownOptions>();

    ngOnInit(): void {
        
    }

    onOptionClick(option: DropdownOptions): void {
        this.selectedOption = option;
        this.select.emit(option);
    }
}