import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DropdownOptions } from "../../models/common.model";
import { UOM_OPTIONS, YES_NO_OPTIONS } from "../../constant/sample-data.constant";
import { GMIViewsService } from "../../service/gmi-views.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import csvDownload from 'json-to-csv-export'
import { ToastrService } from "ngx-toastr";


@Component({
    selector: 'app-bulk-update',
    templateUrl: './bulk-update.component.html',
    styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit {

    uom: DropdownOptions[] = UOM_OPTIONS;
    yesNoOptions: DropdownOptions[] = YES_NO_OPTIONS;
    selectedUom: DropdownOptions = null;
    selectedDiscoutinuedFlag: DropdownOptions = null;
    selectedBadQualityFlag: DropdownOptions = null;
    salesValue: string = null;
    salesVolume: string = null;
    selectedFile: any;
    @Input() viewType: string = '';
    @Input() filterInputs: any = null;
    @Input() totalRows: number = null;

    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    @Output() reload: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('modalCloseButton') modalCloseButton: any;


    constructor(
        private readonly viewService: GMIViewsService,
        private readonly loaderService: NgxUiLoaderService,
        private readonly toastrService: ToastrService
    ){}

    ngOnInit(): void {
    }

    onSelect(event: any): void {

    }

    cancel(): void {
        this.selectedUom = null;
        this.selectedDiscoutinuedFlag = null;
        this.selectedBadQualityFlag = null;
        this.salesValue = null;
        this.salesVolume = null;
        this.selectedFile = null;
    }

    updateRecords(): void {
        if (this.totalRows > 100000 && !this.selectedFile) {
            this.toastrService.warning('Please use filter to reduce the total row count less than 100k for bulk update.', 'Warning');
            return
        }
        if (this.selectedFile) {
            this.uploadFile();
            return;
        }
        let data = null;
        if (this.viewType === 'deactivations') {
            data = {
                BadDataQualityFlag: this.selectedBadQualityFlag?.name,
                DiscontinuedDataFlag: this.selectedDiscoutinuedFlag?.name
            };
        } else {
            data = {
                ItemUnitOfMeasure: this.selectedUom?.name,
                NewSalesValue: this.salesValue,
                NewSalesVolume: this.salesVolume
            };
        }
        this.modalCloseButton.nativeElement.click();
        this.update.emit(data);
        this.cancel();
    }

    downloadFilteredData(): void {
        if (!this.filterInputs?.query || this.totalRows > 100000) {
            this.toastrService.warning('Please use filter to reduce the total row count less than 100k for CSV download.', 'Warning');
            return
        }
        this.loaderService.start();
        const subscription = this.viewService.downloadFile(this.filterInputs).subscribe(response => {
            const headers = Object.keys(response[0]).map(key => key);
            const dataToConvert = {
                data: response,
                filename: 'AIT_HistoryChange',
                delimiter: ',',
                headers: headers
            }
            csvDownload(dataToConvert);
            subscription.unsubscribe();
            this.loaderService.stop();
        }, (error) => {
            this.toastrService.error('Failed to download file.', 'Error');
            subscription.unsubscribe();
            this.loaderService.stop();
        });

    }

    fileSelect(event: any): void {
        const files: FileList = event.target.files;
        this.selectedFile = files.item(0);
    }

    uploadFile(): void {
        if (!this.selectedFile) {
            return;
        }
        this.loaderService.start();
        const subscription = this.viewService.uploadFile(this.selectedFile).subscribe(res => {
            this.modalCloseButton.nativeElement.click();
            subscription.unsubscribe();
            this.loaderService.stop();
            this.toastrService.success('File uploaded successfully. Records will get updated in some time.', 'Success');
        },
        (error) => {
            this.toastrService.error('Failed to upload file.', 'Error');
            console.log(error);
            subscription.unsubscribe();
            this.loaderService.stop();
        });
    }
    


}