<div class="d-flex align-items-center justify-content-center" style="height: 80vh">
    <div class="p-2 m-2 text-black home-options bg-white">
        <div class="border d-flex align-items-center justify-content-center home-message font-weight-bold" style="height: 12%;">
            GMI Add in Tool
        </div>
        <div class="border d-flex align-items-center justify-content-center home-message font-weight-bold" style="height: 12%;">
            Please select one of the following views to continue
        </div>
        <div class="d-flex border h-25 align-items-center justify-content-center">
            <a [routerLink]="['/view', 'reinstatements']">Reinstatement</a>
        </div>
        <div class="d-flex border h-25 align-items-center justify-content-center">
            <a [routerLink]="['/view', 'deactivations']">Deactivation</a>
        </div>
        <div class="d-flex border h-25 align-items-center justify-content-center">
            <a [routerLink]="['/view', 'historyChanges']">History Change</a>
        </div>
    </div>
</div>
