import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  userDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private msalService: MsalService
    ) {

  }

  getActiveUser(): Observable<any> {
    return this.userDetails.asObservable();
  }

  getAllActiveAccounts() {
    return this.msalService.instance.getAllAccounts();
  }

  setActiveAccount(): void {
    const allAccounts = this.msalService.instance.getAllAccounts() || [];
    if (!this.msalService.instance.getActiveAccount() && allAccounts.length > 0) {
      this.msalService.instance.setActiveAccount(allAccounts[0]);
      this.userDetails.next(allAccounts[0]);
    } else {
      const currentUser = this.msalService.instance.getActiveAccount();
      this.userDetails.next(currentUser);
    } 
  }

  signOut() {
    this.msalService.logout();
  }


  refreshToken() {
    return this.msalService.instance.acquireTokenSilent({
      scopes: ['https://analysis.windows.net/powerbi/api/dataset.read.all'],
      account: this.msalService.instance.getActiveAccount() as AccountInfo,
    });
  }
}
