<div class="modal fade" data-bs-backdrop="static" id="filter-modal" tabindex="-1" role="dialog" aria-hidden="false" aria-labelledby="filter-modal-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Apply Filter</h5>
        </div>
        <div class="modal-body">
            <div class="container">
              <div class="row" *ngIf="viewType !== 'historyChanges'">
                <div class="col-4 font-weight-bold">
                  Supplier
                </div>
                <div class="col">
                  <app-dim-dropdown
                    [path]="'suppliers'"
                    [selectedValues]="selectedSupplier"
                    (onSelect)="selectedSupplier = $event"
                  ></app-dim-dropdown>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col">
                  <!-- Geography -->
                  <div class="accordion" id="geography-accordian">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-geography">
                          <button class="accordion-button font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-geograpghy" aria-expanded="true" aria-controls="collapse-geograpghy">
                            Geography
                          </button>
                        </h2>
                        <div id="collapse-geograpghy" class="accordion-collapse collapse" aria-labelledby="heading-geography" data-bs-parent="#geography-accordian">
                          <div class="accordion-body">
                            <div class="container">
                              <div class="row">
                                <div class="col-4 font-weight-bold">
                                  World Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'world'"
                                  [selectedValues]="geopgraphyInputs.world"
                                  (onSelect)="geopgraphyInputs.world = $event;"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Region Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'region'"
                                  [selectedValues]="geopgraphyInputs.region"
                                  (onSelect)="geopgraphyInputs.region = $event; getCountryCodes('region', 'country')"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Cluster Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'cluster'"
                                  [selectedValues]="geopgraphyInputs.cluster"
                                  (onSelect)="geopgraphyInputs.cluster = $event; getCountryCodes('cluster', 'country')"
                                  [query]="geopgraphyInputs.region"
                                  [disabled]="!geopgraphyInputs.region"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Country Cluster Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'country-cluster'"
                                  [selectedValues]="geopgraphyInputs.countryCluster"
                                  (onSelect)="geopgraphyInputs.countryCluster = $event; getCountryCodes('country-cluster', 'country')"
                                  [query]="geopgraphyInputs.cluster"
                                  [disabled]="!geopgraphyInputs.cluster"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Country Group Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'country-group'"
                                  [selectedValues]="geopgraphyInputs.countryGroup"
                                  (onSelect)="geopgraphyInputs.countryGroup = $event; getCountryCodes()"
                                  [query]="geopgraphyInputs.countryCluster"
                                  [disabled]="!geopgraphyInputs.countryCluster"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Country Code
                                </div>
                                <div class="col checkbox-codes border">
                                  <span class="form-check m-3" *ngFor="let country of geopgraphyInputs.country">
                                    <input class="form-check-input" type="checkbox" value="{{country.code}}" id="country-{{country.code}}" [(ngModel)]="country.selected">
                                    <label class="form-check-label" for="country-{{country.code}}">
                                      {{country.code}}
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col">
                  <!-- Product Category -->
                  <div class="accordion" id="product-catogry-accordian">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-product">
                          <button class="accordion-button font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-product" aria-expanded="true" aria-controls="collapse-product">
                            Product Category
                          </button>
                        </h2>
                        <div id="collapse-product" class="accordion-collapse collapse" aria-labelledby="heading-product" data-bs-parent="#product-catogry-accordian">
                          <div class="accordion-body">
                            <div class="container">
                              <div class="row">
                                <div class="col-4 font-weight-bold">
                                  Total Global Category Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'total-global-category'"
                                  [selectedValues]="productAttributeInuputs.totalGlobalCategory"
                                  (onSelect)="productAttributeInuputs.totalGlobalCategory = $event"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Division Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'division'"
                                  [selectedValues]="productAttributeInuputs.division"
                                  (onSelect)="productAttributeInuputs.division = $event; getproductFormCodes('division', 'product')"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Category Group Desc
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'category-group'"
                                  [selectedValues]="productAttributeInuputs.categoryGroup"
                                  (onSelect)="productAttributeInuputs.categoryGroup = $event; getproductFormCodes('categoryGroup', 'product')"
                                  [query]="productAttributeInuputs.division"
                                  [disabled]="!productAttributeInuputs.division"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Sub Division Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'sub-division'"
                                  [selectedValues]="productAttributeInuputs.subDivision"
                                  (onSelect)="productAttributeInuputs.subDivision = $event; getproductFormCodes('subDivision', 'product')"
                                  [query]="productAttributeInuputs.categoryGroup"
                                  [disabled]="!productAttributeInuputs.categoryGroup"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Category Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'category'"
                                  [selectedValues]="productAttributeInuputs.category"
                                  (onSelect)="productAttributeInuputs.category = $event; getproductFormCodes('category', 'product')"
                                  [query]="productAttributeInuputs.subDivision"
                                  [disabled]="!productAttributeInuputs.subDivision"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Market Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'market'"
                                  [selectedValues]="productAttributeInuputs.market"
                                  (onSelect)="productAttributeInuputs.market = $event; getproductFormCodes('market', 'product')"
                                  [query]="productAttributeInuputs.category"
                                  [disabled]="!productAttributeInuputs.category"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Sector Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'sector'"
                                  [selectedValues]="productAttributeInuputs.sector"
                                  (onSelect)="productAttributeInuputs.sector = $event; getproductFormCodes('sector', 'product')"
                                  [query]="productAttributeInuputs.market"
                                  [disabled]="!productAttributeInuputs.market"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Sub Sector Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'sub-sector'"
                                  [selectedValues]="productAttributeInuputs.subSector"
                                  (onSelect)="productAttributeInuputs.subSector = $event; getproductFormCodes('subSector', 'product')"
                                  [query]="productAttributeInuputs.sector"
                                  [disabled]="!productAttributeInuputs.sector"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Segment Name
                                </div>
                                <div class="col">
                                  <app-dim-dropdown
                                  [path]="'segment'"
                                  [selectedValues]="productAttributeInuputs.segment"
                                  (onSelect)="productAttributeInuputs.segment = $event; getproductFormCodes('segment', 'product')"
                                  [query]="productAttributeInuputs.subSector"
                                  [disabled]="!productAttributeInuputs.subSector"
                                ></app-dim-dropdown>
                                </div>
                              </div>
                              <div class="row pt-3">
                                <div class="col-4 font-weight-bold">
                                  Product Form Code
                                </div>
                                <div class="col">
                                  <p-dropdown 
                                      id="{{'dropdown-product-form-code'}}"
                                      [options]="productFormCodes"
                                      [(ngModel)]="productAttributeInuputs.productFormCode"
                                      optionLabel="code"
                                      [virtualScroll]="true"
                                      [filter]="true"
                                      [virtualScrollItemSize]="30"
                                      [showClear]="productAttributeInuputs.productFormCode ? true : false"
                                      placeholder="All">
                                  </p-dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-4 font-weight-bold">
                  Delivery Group
                </div>
                <div class="col">
                  <app-dim-dropdown
                    [path]="'delivery-group'"
                    [selectedValues]="selectedSourceName"
                    (onSelect)="selectedSourceName = $event"
                  ></app-dim-dropdown>
                </div>
              </div>
              <div class="row pt-3" *ngIf="viewType !== 'historyChanges'">
                <div class="col-4 font-weight-bold">
                  Bad Data Quality
                </div>
                <div class="col d-flex">
                  <div class="form-check">
                    <input class="form-check-input radio-button" type="radio" name="bad-data-quality" value="Yes" id="yes-radio" [(ngModel)]="badQuality">
                    <label class="form-check-label" for="yes-radio">
                      Yes
                    </label>
                  </div>
                  <div class="form-check" style="margin-left: 12px;">
                    <input class="form-check-input radio-button" type="radio" name="bad-data-quality" value="No" id="no-radio" [(ngModel)]="badQuality">
                    <label class="form-check-label" for="no-radio">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="row pt-3" *ngIf="viewType !== 'historyChanges'">
                <div class="col-4 font-weight-bold">
                  Discontinued Data Flag
                </div>
                <div class="col d-flex">
                  <div class="form-check">
                    <input class="form-check-input radio-button" type="radio" name="discontinued-data-flag" value="Yes" id="yes-radio" [(ngModel)]="discontinuedFlag">
                    <label class="form-check-label" for="yes-radio">
                      Yes
                    </label>
                  </div>
                  <div class="form-check" style="margin-left: 12px;">
                    <input class="form-check-input radio-button" type="radio" name="discontinued-data-flag" value="No" id="no-radio" [(ngModel)]="discontinuedFlag">
                    <label class="form-check-label" for="no-radio">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="row pt-3" *ngIf="viewType === 'historyChanges'">
                <div class="col-4 font-weight-bold">
                  Unit of Measure
                </div>
                <div class="col">
                  <app-dim-dropdown
                    [values]="UOM"
                    [singleSelection]="false"
                    [selectedValues]="selectedUOM"
                    (onSelect)="selectedUOM = $event"
                  ></app-dim-dropdown>
                </div>
              </div>
              <div class="row pt-3" *ngIf="viewType === 'historyChanges'">
                <div class="col-4 font-weight-bold">
                  Global Customer Channel Type Code
                </div>
                <div class="col">
                  <app-dim-dropdown
                    [path]="'channel'"
                    [singleSelection]="false"
                    [selectedValues]="selectedChannel"
                    (onSelect)="selectedChannel = $event"
                  ></app-dim-dropdown>
                </div>
              </div>
              <div class="row pt-3" *ngIf="viewType === 'historyChanges'">
                <div class="col-4 font-weight-bold">
                  Measure Type
                </div>
                <div class="col">
                  <app-dim-dropdown
                    [values]="measureTypeOptions"
                    [singleSelection]="false"
                    [selectedValues]="selectedMeasureType"
                    (onSelect)="selectedMeasureType = $event"
                  ></app-dim-dropdown>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-4 font-weight-bold">
                  Local Brand Name
                </div>
                <div class="col">
                  <app-dim-dropdown
                    [path]="'brand'"
                    [singleSelection]="false"
                    [selectedValues]="selectedBrand"
                    (onSelect)="selectedBrand = $event"
                  ></app-dim-dropdown>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-4 font-weight-bold">
                  Local Manufacturer Name
                </div>
                <div class="col">
                  <app-dim-dropdown
                    [path]="'local-manufacturer'"
                    [singleSelection]="false"
                    [selectedValues]="selectedLocalManufaturer"
                    (onSelect)="selectedLocalManufaturer = $event"
                  ></app-dim-dropdown>
                </div>
              </div>
              <div class="row pt-3" *ngIf="viewType === 'historyChanges'">
                <div class="col-2 font-weight-bold pt-2">
                  <span class="required">*</span>Start Date
                </div>
                <div class="col-4">
                  <p-calendar [(ngModel)]="startDate" dateFormat="yy-mm-dd" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                </div>
                <div class="col-2 font-weight-bold pt-2">
                  <span class="required">*</span>End Date
                </div>
                <div class="col-4">
                  <p-calendar [(ngModel)]="endDate" dateFormat="yy-mm-dd" [iconDisplay]="'input'" [showIcon]="true" class="end-date-calender"></p-calendar>
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-info mr-auto" (click)="resetForm()">Reset</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" [disabled]="viewType === 'historyChanges' && !(startDate && endDate)" class="btn btn-primary" data-bs-dismiss="modal" (click)="filter()">Filter</button>
        </div>
      </div>
    </div>
</div>