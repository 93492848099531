<div class="text-white p-3">
    <div class="row">
        <div class="col">
            Select Type:
            <app-dropdown
            [options]="viewOptions"
            [selectedOption]="selectedView"
            (select)="routeToView($event)"
            ></app-dropdown>
        </div>
        <div class="col">
            <button type="button" class="btn btn-primary font-weight-bold" data-bs-toggle="modal" data-bs-target="#filter-modal">Apply Filter</button>
        </div>
        <div class="col">
            <button type="button" class="btn btn-primary font-weight-bold" [disabled]="isSubmitDisabled" (click)="rowUpdate(selectedRow)">Submit</button>
        </div>
        <div class="col">
            <button type="button" class="btn btn-primary font-weight-bold" data-bs-toggle="modal" data-bs-target="#bulk-update-modal" *ngIf="selectedView?.code !== 'reinstatements'">Bulk Update</button>
        </div>
    </div>
</div>
<div class="p-3">
    <div class="row">
        <div class="col">
            <p-table 
                #pTable
                [columns]="columns" 
                [value]="rows" 
                [lazy]="true" 
                (onLazyLoad)="onPageChange($event)" 
                [paginator]="true" 
                [rows]="pageSizes[0]" 
                editMode="row"
                [dataKey]="datakey"
                [lazyLoadOnInit]="false"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records" 
                [totalRecords]="totalRecords" 
                [rowsPerPageOptions]="pageSizes">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <th style="position: sticky; left: -1px; z-index: 1; top: -1px; z-index: 999" *ngIf="col.field === 'action'">
                                {{col.header}}
                            </th>
                            <th *ngIf="col.field !== 'action'" style="z-index: 99">
                                {{col.header}}
                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row let-columns="columns" let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="row" #tableRowElement>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.field !== 'action' && !col?.editable">
                                <ng-container *ngIf="col.fieldType !== 'date'">
                                    {{row[col.field]}}
                                </ng-container>
                                <ng-container *ngIf="col.fieldType === 'date'">
                                    {{row[col.field] | date:'yyyy-MM-dd HH:mm:ss' }}
                                </ng-container>
                            </td>
                            <td *ngIf="col.editable && col.type === 'select' && col.field !== 'action'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown 
                                            id="{{'dropdown-' + col.field}}"
                                            appendTo="body"
                                            [options]="editableRowOptions[selectedView.code][col.field]"
                                            [(ngModel)]="row[col.field]"
                                            optionLabel="name"
                                            [virtualScroll]="true"
                                            [filter]="true"
                                            [virtualScrollItemSize]="30"
                                            showClear="false"
                                            (onChange)="onSelect($event, col.field, row)"
                                            [placeholder]="''">
                                        </p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{row[col.field]?.name}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td *ngIf="col.editable && col.type === 'text' && col.field !== 'action'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-inputNumber mode="decimal" inputId="withoutgrouping" [useGrouping]="false" [(ngModel)]="row[col.field]"> </p-inputNumber>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{row[col.field]}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pFrozenColumn style="position: sticky; left: -1px; z-index: 99" *ngIf="col.field === 'action'">
                                <div class="center-item">
                                    <i *ngIf="!editing" pRipple pInitEditableRow (click)="onEdit(row)" class="pi pi-pencil cursor-pointer" style="font-size: 1.2rem"></i>
                                    <i *ngIf="editing" pRipple pCancelEditableRow class="pi pi-times cursor-pointer" (click)="onCancelEdit(row)" style="font-size: 1.2rem"></i>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div> 
</div>

<!-- Filter Model -->
<app-filter [viewType]="selectedView.code" (filterClick)="filterRows($event)"></app-filter>

<!-- Bulk Update Modal -->
<app-bulk-update [viewType]="selectedView.code" [totalRows]="totalRecords" [filterInputs]="filterInputs" (update)="updateRecords($event)" (reload)="getViewData(selectedView?.code)"></app-bulk-update>

<ngx-ui-loader [fgsColor]="loaderColor"></ngx-ui-loader>
