import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/service/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    private readonly authService: AuthService,
    private readonly idleService: BnNgIdleService
  ){}

  ngOnInit(): void {
    this.idleService.startWatching(900).subscribe((res) => {
      if (res) {
        console.log('The  session  expired');
        this.authService.signOut();
      }
    });
  }
}
