<div class="modal fade" data-bs-backdrop="static" id="bulk-update-modal" tabindex="-1" role="dialog" aria-hidden="false" aria-labelledby="bulk-update-modal-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk Update</h5>
        </div>
        <div class="modal-body">
            <div class="container" *ngIf="viewType === 'deactivations'">
              <div class="row">
                <div class="col-6">
                  <label class="font-weight-bold">Bad Data Quality Flag</label>
                  <p-dropdown 
                      id="dropdown-bad-quality"
                      [options]="yesNoOptions"
                      [(ngModel)]="selectedBadQualityFlag"
                      optionLabel="name"
                      [virtualScroll]="true"
                      [filter]="true"
                      [virtualScrollItemSize]="30"
                      showClear="true"
                      (onChange)="onSelect($event)"
                      [placeholder]="'Select'">
                  </p-dropdown>
                </div>
                <div class="col-6">
                  <label class="font-weight-bold">Discoutinued Data Flag</label>
                  <p-dropdown 
                      id="dropdown-discountinued-flag"
                      [options]="yesNoOptions"
                      [(ngModel)]="selectedDiscoutinuedFlag"
                      optionLabel="name"
                      [virtualScroll]="true"
                      [filter]="true"
                      [virtualScrollItemSize]="30"
                      showClear="true"
                      (onChange)="onSelect($event)"
                      [placeholder]="'Select'">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="container" *ngIf="viewType === 'historyChanges'">
              <div class="row">
                <div class="col-4">
                  <label class="font-weight-bold">Item Unit of Measure</label>
                  <p-dropdown 
                      id="dropdown-uom"
                      [options]="uom"
                      [(ngModel)]="selectedUom"
                      optionLabel="name"
                      [virtualScroll]="true"
                      [filter]="true"
                      [virtualScrollItemSize]="30"
                      showClear="true"
                      (onChange)="onSelect($event)"
                      [placeholder]="'Select'">
                  </p-dropdown>
                </div>
                <div class="col-4">
                  <label class="font-weight-bold">New Sales Value</label>
                  <p-inputNumber mode="decimal" inputId="withoutgrouping" [useGrouping]="false" placeholder="Add New Sales Value" [(ngModel)]="salesValue"> </p-inputNumber>
                </div>
                <div class="col-4">
                  <label class="font-weight-bold">New Sales Volume</label>
                  <p-inputNumber mode="decimal" inputId="withoutgrouping" [useGrouping]="false" placeholder="Add New Sales Volume" [(ngModel)]="salesVolume"> </p-inputNumber>
                </div>
              </div>
              <div class="row"></div>
              <div class="divider">OR</div>
              <div class="row m-5">
                <div class="col">
                  <button type="button" class="btn btn-primary font-weight-bold btn-middle" (click)="downloadFilteredData()">Download CSV</button>
                </div>
                <div class="col">
                  <button type="button" class="btn btn-primary font-weight-bold btn-middle" (click)="fileInput.click()">Upload CSV</button>
                  <input #fileInput type="file" name="" accept=".csv" (change)="fileSelect($event)" hidden/>
                  <div class="selected-file" *ngIf="selectedFile?.name">{{selectedFile?.name}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b>Note:</b> Please update only following column fields 'ItemUnitOfMeasure', 'NewSalesValue', 'HistoryFlag' and 'NewSalesVolume' in csv while uploading for bulk update.
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <div class="note-section">
            <b>Note:</b> Please use filter to reduce the total row count for bulk update.
          </div>
          <button #modalCloseButton type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="cancel()">Cancel</button>
          <button type="button" class="btn btn-primary" [disabled]="!selectedFile && !(selectedUom || salesValue || salesVolume) && !(selectedUom || selectedDiscoutinuedFlag || selectedBadQualityFlag)" (click)="updateRecords()">Update</button>
        </div>
      </div>
    </div>
</div>