import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class GmiDropdownService {

    url: string = environment.apiPath;
    constructor(
        private readonly httpClient: HttpClient
    ){

    }

    getCountryMetaData(geographyInput: any): Observable<any> {
        return this.httpClient.post<any>(`${this.url}/api/dim/country`, geographyInput);
    }

    getProductFormMetaData(productAttributeInput: any): Observable<any> {
        return this.httpClient.post<any>(`${this.url}/api/dim/product-form`, productAttributeInput);
    }

    getDropdownValues(path: string, code?: string): Observable<any> {
        let url = `${this.url}/api/dim/${path}`;
        if (code) {
            url = `${this.url}/api/dim/${path}?code=${code}`
        }
        return this.httpClient.get<any>(url);
    }
}